
<template>
    <div class="reward" v-loading="status === 'loading'">
        <div class="zk-main-title">
            <h2>奖励活动</h2>
        </div>
        <error-data v-if="status === 'error'">
            请求错误, 请重试
        </error-data>
        <no-data v-if="!allActivities.length">
            暂未奖励活动
        </no-data>
        <template v-if="status === 'success' && !!allActivities.length">
            <div class="reward-wrapper">
                <div class="reward-inner" style="position: relative;">
                    <div class="reward-info">
                        <div class="reward-info-block">
                            来源
                            <el-select class="reward-middle1" v-model="bizType" placeholder="请选择"
                                       @change="handleBizTypeChange"
                            >
                                <el-option
                                    label="全部"
                                    :value="-1"
                                />
                                <el-option
                                    label="考拉海购"
                                    :value="0"
                                />
                                <el-option
                                    label="猫享自营"
                                    :value="1"
                                />
                                <el-option
                                    label="天猫商家"
                                    :value="2"
                                />
                            </el-select>
                        </div>
                        <div class="reward-info-block">
                            活动类型
                            <el-select class="reward-middle1" v-model="activityType" placeholder="请选择"
                                       @change="handleActivityTypeChange"
                            >
                                <el-option v-for="item in activityTypeList"
                                           :key="item.activityType"
                                           :label="item.activityTypeName"
                                           :value="item.activityType"
                                />
                            </el-select>
                        </div>
                        <div class="reward-info-block">
                            活动名称
                            <el-select class="reward-middle" v-model="activityId" placeholder="请选择"
                                       @change="handleActivityChange"
                            >
                                <el-option v-for="item in activities"
                                           :key="item.activityId"
                                           :label="item.activityName"
                                           :value="item.activityId"
                                />
                            </el-select>
                        </div>

                        <div class="reward-info-block"
                             v-if="!!activity.activityId && activity.activityStatus !== 1 && !remainTime"
                        >
                            <el-tag class="reward-middle" type="info">
                                活动时间：{{ activity.startTime | formatTime('MM-dd HH:mm:ss') }}
                                - {{ activity.endTime | formatTime('MM-dd HH:mm:ss') }}
                            </el-tag>

                            <el-link type="primary"
                                     :href="`/promotion/reward/rewardDetail?activityId=${activity.activityId}`"
                                     target="_blank"
                                     style="position: absolute; top: 26px; right: 40px;"
                            >
                                活动详情
                            </el-link>
                        </div>

                        <div class="reward-info-block"
                             v-if="!!activity.activityId && activity.activityStatus === 1 && !!remainTime"
                        >
                            <span class="reward-middle">距结束还剩：</span>
                            <el-tag :type="remainTime.d >= 3 ? 'warning' : 'danger'"
                                    class="reward-middle"
                            >
                                {{ remainTime | formatRemainTime }}
                            </el-tag>
                        </div>
                    </div>
                    <div class="reward-detail" v-if="!!activity.activityId">
                        <template v-if="activity.activityType === 'TMALL_ZHUANKE_INVITE_NEW' || 
                            activity.activityType === 'TAOBAO_ZHUANKE_INVITE_NEW'"
                        >
                            <div class="reward-amount">
                                <p class="tip">
                                    新用户首登且首购
                                </p>
                                <p class="money">{{ extensionDataTable[3].newNum }}</p>
                            </div>
                            <div class="reward-amount">
                                <p class="tip">
                                    非新用户当日首单
                                </p>
                                <p class="money">{{ extensionDataTable[3].noNewNum }}</p>
                            </div>
                        </template>

                        <div class="reward-amount" v-else>
                            <p class="tip">
                                {{ myOrderNumTitleAndTip.title }}
                                <reward-tooltip v-if="!!myOrderNumTitleAndTip.tip">
                                    {{ myOrderNumTitleAndTip.tip }}
                                </reward-tooltip>
                            </p>
                            <p class="money">{{ activity | formatRewardActivityThrehold }}</p>
                        </div>

                        <div class="reward-amount un">
                            <p class="tip">
                                <el-tag
                                    :type="activity.settleStatus === REWARD_ACTIVITY_SETTLE_STATUS.PENDING ? 'warning' : 'success'"
                                    size="mini"
                                >
                                    {{ activity.settleStatus | formatRewardActivitySettleStatus }}
                                </el-tag>
                                {{ activity.settleStatus === REWARD_ACTIVITY_SETTLE_STATUS.PENDING ? '预估活动服务费' : '活动服务费' }}

                                <reward-tooltip v-if="activity.settleStatus === 1">
                                    参与{{ bizTypeFindByKey(activity.bizType,'tagText') }}{{ type | formatRewardType2FullName }}活动所获得的奖金（不含商品佣金），本数据为预估值，仅供参考。<br>
                                    最终收入金额以活动结算后您账户内实际收到的为准。
                                </reward-tooltip>
                            </p>
                            <template v-if="activity.activityType === 'TMALL_ZHUANKE_INVITE_NEW' || 
                                activity.activityType === 'TAOBAO_ZHUANKE_INVITE_NEW'"
                            >
                                <p class="money"><span class="symbol">&yen;</span>{{ totalAwards || 0 }}</p>
                            </template>
                            <template v-else>
                                <p class="money"><span class="symbol">&yen;</span>{{ activity.myReward | formatPrice }}</p>
                            </template>
                        </div>

                        <div class="reward-detail">
                            <el-alert :title="`“${myOrderNumTitleAndTip.measure}“和”预估活动服务费“仅供参考，实际数据会根据订单是否发生售后产生变化，以最终结算为准。`"
                                      type="warning"
                                      show-icon
                            />
                        </div>

                        <div class="reward-level" :class="{ 'type3': type === 3 }"
                             v-if="activity.hierarchies && 
                                 activity.zhuankeRewardType === 1 && 
                                 activity.activityType != 'TMALL_ZHUANKE_INVITE_NEW' &&
                                 activity.activityType != 'TAOBAO_ZHUANKE_INVITE_NEW'"
                        >
                            <p class="reward-level-tip" v-if="type === 3">实付279元·年黑卡：{{ activity.myOrderNum }} 张</p>
                            <level :levels="activity.hierarchies"
                                   :my-order-num="type === 4 || type == 'TMALL_GMV_REWARD' || type == 'TAOBAO_GMV_REWARD' ? activity.orderAmount : activity.myOrderNum"
                                   :reward-limit="activity.rewardLimit"
                                   :type="type"
                            />
                        </div>
                    </div>
                </div>
                <div style="height: 14px; background: #f5f5f5;" />
                <template v-if="!!activity.activityId && activity.activityType != 'TMALL_FLOW_USR_CPA'">
                    <div class="reward-limit-box">
                        <h4 v-if="activity.rewardLimit">最高奖励<span class="reward-limit-unit">&nbsp;{{ activity.rewardLimit }}</span>元</h4>
                    </div>
                    <!-- 猫享拉新活动 -->
                    <div class="reward-inner"
                         v-show="activity.activityType === 'TMALL_ZHUANKE_INVITE_NEW' ||
                             activity.activityType === 'TAOBAO_ZHUANKE_INVITE_NEW'"
                    >
                        <h3>推广奖励进度<span class="reward-inner-sub-title">（次日更新）</span></h3>
                        <el-table ref="inviteTableRef" :data="extensionDataTable" style="width: 100%">
                            <el-table-column prop="label" label="推广奖励数据" />
                            <el-table-column prop="newNum" label="新用户首登且首购" />
                            <el-table-column prop="noNewNum" label="非新用户当日首单" />
                        </el-table>
                    </div>

                    <!-- 其他活动 -->
                    <div class="reward-inner"
                         v-show="activity.activityType !== 'TMALL_ZHUANKE_INVITE_NEW' &&
                             activity.activityType !== 'TAOBAO_ZHUANKE_INVITE_NEW'"
                    >
                        <h3>{{ bizTypeFindByKey(activity.bizType,'tagText') }}{{ type | formatRewardType2OrderName }}</h3>
                        <div class="reward-form">
                            <el-form :inline="true">
                                <el-form-item label="下单时间"
                                              v-if="type === 1 || 
                                                  type == 'TMALL_ORDER_NUM_REWARD' || 
                                                  type == 'TAOBAO_ORDER_NUM_REWARD' || 
                                                  type == 'TAOBAO_RED_ORDER_NUM_REWARD'"
                                >
                                    <el-date-picker style="width: 380px;"
                                                    type="daterange"
                                                    v-model="rangeDate"
                                                    range-separator="至"
                                                    :picker-options="pickerOptions"
                                                    start-placeholder="开始日期"
                                                    end-placeholder="结束日期"
                                                    @change="handleRangeChange"
                                    />
                                </el-form-item>
                                <el-form-item label="新客首单时间" 
                                              v-if="type === 2 ||
                                                  type == 'TMALL_ZHUANKE_INVITE_NEW'||
                                                  type == 'TAOBAO_ZHUANKE_INVITE_NEW'"
                                >
                                    <el-date-picker style="width: 380px;"
                                                    type="daterange"
                                                    v-model="rangeDate"
                                                    range-separator="至"
                                                    :picker-options="pickerOptions"
                                                    start-placeholder="开始日期"
                                                    end-placeholder="结束日期"
                                                    @change="handleRangeChange"
                                    />
                                </el-form-item>
                                <el-form-item label="开通会员时间" v-if="type === 3">
                                    <el-date-picker style="width: 380px;"
                                                    type="daterange"
                                                    v-model="rangeDate"
                                                    range-separator="至"
                                                    :picker-options="pickerOptions"
                                                    start-placeholder="开始日期"
                                                    end-placeholder="结束日期"
                                                    @change="handleRangeChange"
                                    />
                                </el-form-item>
                                <el-form-item label="下单时间" 
                                              v-if="type === 4 || 
                                                  type == 'TMALL_GMV_REWARD'||
                                                  type == 'TAOBAO_GMV_REWARD'"
                                >
                                    <el-date-picker style="width: 380px;"
                                                    type="daterange"
                                                    v-model="rangeDate"
                                                    range-separator="至"
                                                    :picker-options="pickerOptions"
                                                    start-placeholder="开始日期"
                                                    end-placeholder="结束日期"
                                                    @change="handleRangeChange"
                                    />
                                </el-form-item>
                                <el-form-item label="结算状态" v-if="type === 1 || type === 4 || type === 5 ||
                                    type === 'TMALL_ORDER_NUM_REWARD' || type === 'TMALL_GMV_REWARD' || 
                                    type === 'TMALL_GMV_RATE'||
                                    type === 'TAOBAO_ORDER_NUM_REWARD' || type === 'TAOBAO_GMV_REWARD' || 
                                    type === 'TAOBAO_GMV_RATE' || type === 'TAOBAO_RED_ORDER_NUM_REWARD'"
                                >
                                    <el-select class="reward-middle"
                                               v-model="formData.settleStatus"
                                               placeholder="请选择"
                                               @change="startSearch"
                                    >
                                        <el-option v-for="item in settleStatuses"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value"
                                        />
                                    </el-select>
                                </el-form-item>
                                <el-form-item class="export-btn">
                                    <el-button type="primary" @click="showExportOrdersPrompt">导出</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                        <el-table
                            :data="list"
                            style="width: 100%"
                            v-if="type === 1 || type === 4 || type === 5 ||
                                type === 'TMALL_ORDER_NUM_REWARD' || type === 'TMALL_GMV_REWARD' || 
                                type === 'TMALL_GMV_RATE'||
                                type === 'TAOBAO_ORDER_NUM_REWARD' || type === 'TAOBAO_GMV_REWARD' || 
                                type === 'TAOBAO_GMV_RATE' || type === 'TAOBAO_RED_ORDER_NUM_REWARD' "
                        >
                            <el-table-column prop="orderId" label="订单号" />
                            <el-table-column label="订单来源">
                                <template slot-scope="scope">
                                    <p>{{ scope.row.bizType | formatBizType }}</p>
                                </template>
                            </el-table-column>
                            <el-table-column label="下单时间">
                                <template slot-scope="scope">
                                    {{ scope.row.orderTime | formatTime }}
                                </template>
                            </el-table-column>
                            <el-table-column label="推广位" prop="positionName" />
                            <el-table-column>
                                <template slot="header">
                                    <div style="padding-left: 0;">
                                        <span>结算状态</span>
                                        <reward-tooltip>
                                            <p>待结算：订单交易完成，且处于该订单对应的结算期。</p>
                                            <p>已结算：订单结算完成，每月10日，对上个自然月确认收货订单进行结算。</p>
                                            <p>已失效：订单已取消或订单内商品全部发生售后，无需结算的订单。</p>
                                        </reward-tooltip>
                                    </div>
                                </template>
                                <template slot-scope="scope">
                                    <el-tag :type="scope.row.settleStatus | formatSettleStatusType">
                                        {{ scope.row.settleStatus | formatRewardOrderSettleStatus }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" type="expand">
                                <template slot-scope="scope">
                                    <div class="reward-expand-content"
                                         v-if="scope.row.zhuankeOrderItemList&&scope.row.zhuankeOrderItemList.length"
                                    >
                                        <el-table
                                            :data="scope.row.zhuankeOrderItemList"
                                            style="width: 100%" :row-style="{ background: '#f7f7f7' }"
                                            :header-cell-style="{ background: '#f7f7f7' }"
                                        >
                                            <el-table-column prop="goodsName" label="商品名称" />
                                            <el-table-column prop="goodsId" label="商品id" />
                                            <el-table-column prop="categoryName" label="类目" />
                                            <el-table-column prop="buyCount" label="购买数量" />
                                            <el-table-column label="实际成交价">
                                                <template slot-scope="sc">
                                                    &yen;{{ sc.row.payAmount | formatPrice }}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="佣金比例">
                                                <template slot-scope="sc">
                                                    {{ sc.row.commissionRate | formatRate }}
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="commissionAmount" label="预估佣金">
                                                <template slot-scope="sc">
                                                    &yen;{{ sc.row.commissionAmount | formatPrice }}
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                    <div v-else
                                         style="text-align: center; background: #f5f5f5;line-height: 48px;"
                                    >
                                        暂无数据
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>

                        <el-table
                            :data="newerList"
                            style="width: 100%"
                            v-if="type === 2 ||
                                type === 'TMALL_ZHUANKE_INVITE_NEW'||
                                type === 'TAOBAO_ZHUANKE_INVITE_NEW'"
                        >
                            <el-table-column prop="nickName" label="新客昵称" />
                            <el-table-column label="首单支付时间">
                                <template slot-scope="scope">
                                    {{ scope.row.firstOrderPayTime | formatTime }}
                                </template>
                            </el-table-column>
                            <el-table-column label="推广位" prop="positionName" />
                            <el-table-column label="新客审核状态" prop="statusDesc" />
                        </el-table>

                        <el-table
                            :data="vipList"
                            style="width: 100%"
                            v-show="type === 3"
                        >
                            <el-table-column prop="userName" label="会员考拉昵称" />
                            <el-table-column label="开通会员时间">
                                <template slot-scope="scope">
                                    {{ scope.row.openVipTime | formatTime }}
                                </template>
                            </el-table-column>
                            <el-table-column label="开通会员方式">
                                <template slot-scope="scope">
                                    {{ scope.row.type | formatVipType }}
                                </template>
                            </el-table-column>
                            <el-table-column label="推广位" prop="positionName" />
                            <el-table-column label="会员状态">
                                <template slot-scope="scope">
                                    {{ scope.row.status | formatVipStatus }}
                                </template>
                            </el-table-column>
                        </el-table>


                        <div class="reward-page" v-if="totalCount">
                            <pagination @size-change="handleSizeChange"
                                        @current-change=" handleCurrentChange"
                                        :current-page="formData.pageIndex"
                                        :page-size="formData.pageSize"
                                        :total="totalCount"
                            />
                        </div>
                    </div>
                </template>
                <no-data v-if="!activities.length">
                    暂未参加该类型奖励活动
                </no-data>
            </div>
        </template>

        <export-email-dialog :visible.sync="exportVisible"
                             @email-value="getExportEmail"
        />
    </div>
</template>

<script>
import moment from 'moment';
import Pagination from '../../components/common/pagination';
import Tooltip from '../../components/settle/tooltip';
import Countdown from '../../utils/count-down';
import NoData from '../../components/common/noData';
import ErrorData from '../../components/common/errorData';
import Level from '../../components/report/level';
import ExportEmailDialog from '../../components/settle/export-email';
import getDayEnd from '../../utils/get-day-end';
import {
    REWARD_ACTIVITY_FILTER_TYPES,
    REWARD_ACTIVITY_FILTER_TYPES_MX,
    REWARD_ACTIVITY_FILTER_TYPES_TB,
    REWARD_ORDER_FILTER_SETTLE_STATUS,
    REWARD_ACTIVITY_SETTLE_STATUS,
    filterRewardList,
    formatRewardType2FullName,
    formatRewardType2OrderName,
    formatRewardOrderSettleStatus,
    formatRewardActivitySettleStatus,
    formatMyOrderNumTitleAndTip,
    formatRewardActivityThrehold
} from '../../utils/reward';
import { bizTypeFindByKey } from '../../utils/mapBizType.js';

export default {
    data() {
        return {
            status: 'loading',
            activities: [],
            allActivities: [],
            activity: {},
            activityId: '',
            activityType: 0,
            bizType: -1,
            orders: [],
            rangeDate: [],
            formData: {
                settleStatus: -1,
                pageIndex: 1,
                pageSize: 40
            },
            remainTime: null,
            list: [],
            totalCount: 0,
            pickerOptions: {
                disabledDate() {
                    return true;
                }
            },
            exportVisible: false,
            activityTypeList: [],
            type: 2,
            newerList: [],
            vipList: [],
            settleStatuses: REWARD_ORDER_FILTER_SETTLE_STATUS,
            REWARD_ACTIVITY_SETTLE_STATUS,


            extensionDataTable: [
                {label: '昨日奖励用户数', newNum: 0, noNewNum: 0},
                {label: '全周期奖励用户数', newNum: 0, noNewNum: 0},
                {label: '单用户奖励单价', newNum: 0, noNewNum: 0},
                {label: '总预计奖励金额', newNum: 0, noNewNum: 0}
            ],
            tbRowKey: ['label', 'newNum', 'noNewNum'],
            tbListKey: ['yestodayNum', 'userNum', 'unitAmount', 'amount']
        };
    },

    components: {
        'reward-tooltip': Tooltip,
        pagination: Pagination,
        'no-data': NoData,
        'error-data': ErrorData,
        level: Level,
        'export-email-dialog': ExportEmailDialog
    },

    created() {
        this.handleBizTypeChange();
    },

    computed: {
        myOrderNumTitleAndTip() {
            return formatMyOrderNumTitleAndTip(this.type, this.activity.settleStatus);
        },
        totalAwards(){
            return (Number(this.extensionDataTable[3].newNum) + Number(this.extensionDataTable[3].noNewNum)) > this.activity.rewardLimit ? 
                this.activity.rewardLimit :
                (Number(this.extensionDataTable[3].newNum) + Number(this.extensionDataTable[3].noNewNum));
        }
    },

    filters: {
        formatRewardOrderSettleStatus,
        formatRewardActivitySettleStatus,
        formatRewardType2OrderName,
        formatRewardType2FullName,
        formatRewardActivityThrehold,

        formatVipType(v){
            switch (v) {
            case 1:
                return '实付279';
            case 2:
                return '先享后付';
            default:
                return '';
            }
        },
        formatVipStatus(v){
            switch (v) {
            case 1:
                return '正常';
            case 2:
                return '失效';
            default:
                return '';
            }
        },
        formatSettleStatusType(v) {
            switch (v) {
            case 0:
                return 'danger';
            case 1:
                return 'success';
            case 2:
                return 'info';
            default:
                return 'info';
            }
        },

        formatRemainTime(t) {
            let text = '';
            if(t.d) {
                text += `${t.d}天`;
            }
            if(t.h || t.d) {
                text += `${t.hh}时`;
            }
            if(t.m || t.h) {
                text += `${t.mm}分`;
            }
            if(t.s) {
                text += `${t.ss}秒`;
            }
            return text;
        },

        formatRate(v) {
            return `${(v * 1000 / 10).toFixed(2)}%`;
        }
    },

    destroyed() {
        this.destroyCountDown();
    },

    methods: {
        bizTypeFindByKey,
        async handleBizTypeChange(val) {
            this.status = 'loading';
            try {
                await this.getActivities();
            } catch (e) {
                this.status = 'error';
            }
            this.activityTypeList = [];
            if(val === 1){
                this.activityType = '';
                this.activityTypeList = JSON.parse(JSON.stringify([...REWARD_ACTIVITY_FILTER_TYPES_MX]));
            }else if(val === 2){
                this.activityType = '';
                this.activityTypeList = JSON.parse(JSON.stringify([...REWARD_ACTIVITY_FILTER_TYPES_TB]));
            }else if(val === -1 || val == undefined){
                this.activityType = 0;
                this.activityTypeList = JSON.parse(JSON.stringify([
                    ...REWARD_ACTIVITY_FILTER_TYPES, 
                    ...(REWARD_ACTIVITY_FILTER_TYPES_MX.slice(1)),
                    ...(REWARD_ACTIVITY_FILTER_TYPES_TB.slice(1))
                ]));
            }else{
                this.activityType = 0;
                this.activityTypeList = JSON.parse(JSON.stringify([...REWARD_ACTIVITY_FILTER_TYPES]));
            }

            // 新增猫享类型后 处理类型tag
            this.activityTypeList.map((item) => {
                if(item.activityType === 'TMALL_ZHUANKE_INVITE_NEW' || 
                item.activityType === 'TMALL_FLOW_USR_CPA'){ //特殊区分
                    item.activityTypeName = `手猫${item.activityTypeName}`;
                    return item;
                }

                if(typeof(item.activityType)=='string' && item.activityType.includes('TMALL_')){
                    item.activityTypeName = `猫享${item.activityTypeName}`;
                }

                if(typeof(item.activityType)=='string' && item.activityType.includes('TAOBAO_')){
                    item.activityTypeName = `天猫${item.activityTypeName}`;
                }
                return item;
            });
        },
        handleActivityTypeChange() {
            this.activities = filterRewardList(this.allActivities, this.activityType);
            if(this.bizType == 1){ // 区分猫享情况
                this.type = this.activityType === '' ? 'TMALL_ORDER_NUM_REWARD' : this.activityType;
            }else if(this.bizType == 2){ // 区分猫享情况
                this.type = this.activityType === '' ? 'TAOBAO_ORDER_NUM_REWARD' : this.activityType;
            }else{
                this.type = this.activityType === 0 ? 1 : this.activityType;
            }

            if(this.activities.length > 0) {
                this.activityId = this.activities[0].activityId;
                this.getRewardActivityDetail();
            } else {
                this.activityId = '';
                this.activity = {};
            }
        },
        /* eslint-disable */
        handleRangeChange(v) {
            const s = +new Date(v[0]);
            const e = getDayEnd(+new Date(v[1]));
            if(e - s > 1000 * 60 * 60 * 24 * 30) {
                this.$message.error('您选择的时间区间过长, 最多选择一个月的数据');
                return;
            }
            this.startSearch();
        },

        async getActivities() {
            const params = {
                bizType: this.bizType
            };
            const data = await this.$request({
                url: '/api/report/activity/list',
                toast: false,
                params
            });

            this.activities = data || [];
            this.allActivities = data || [];
            /* eslint-disable */
            if(!this.activities.length) {
                this.status = 'success';
                return;
            }

            this.$nextTick(() => {
                this.activityId = Number(this.$route.query.activityId);
                if(!this.activityId) {
                    this.activityId = this.activities[0].activityId;
                }

                this.getRewardActivityDetail();
            });
            /* eslint-enable */
        },

        /* eslint-disable */
        async getRewardActivityDetail() {
            try {
                const result = await this.$request({
                    url: '/api/report/activity/detail',
                    params: {
                        activityId: this.activityId
                    },
                    origin: true,
                    toast: false
                });
                if(result.code === 200) {
                    if(this.activityType === 0) {
                        this.allActivities.filter(value => {
                           if(value.activityId === this.activityId) {
                               this.type = value.activityType;
                           }
                        })
                    }
                    this.handleActivityData(result.data || {});
                    this.startSearch();
                    this.startCountDown();

                    this.status = 'success';
                    return;
                }

                this.$message.error('当前活动暂未产生订单，还请努力哦~');
            } catch (e) {
                console.log(e);
                this.status = 'error';
            }
        },
        handleActivityData(data) {
            this.remainTime = null;
            data.benefitRule = data.zhuankeRewardActivityBenefitRule || {};
            data.hierarchies = data.benefitRule.hierarchies || [];
            data.rewardLimit = data.benefitRule.rewardLimit || 0;
            data.zhuankeRewardType = data.benefitRule.zhuankeRewardType || 1;
            data.singleAmount = data.benefitRule.singleAmount || 0;
            this.activity = data;
            let endTimeNew = new Date(moment(this.activity.endTime).add(1,'d')._d).getTime();

            if(this.activity.activityStatus === 2) {
                if(this.activity.activityType == 'ZHUANKE_INVITE_VIP'){
                    this.rangeDate = [this.activity.startTime, endTimeNew];
                    this.pickerOptions.disabledDate = (time) =>
                        time.getTime() < this.activity.startTime ||
                                time.getTime() > endTimeNew;
                    return;
                }
                    this.rangeDate = [this.activity.startTime, this.activity.endTime];
                    this.pickerOptions.disabledDate = (time) =>
                        time.getTime() < this.activity.startTime ||
                        time.getTime() > this.activity.endTime;
                    return;
            }
                this.rangeDate = [this.activity.startTime, +new Date()];
                this.pickerOptions.disabledDate = (time) => time.getTime() < this.activity.startTime ||
                    time.getTime() > +new Date();
           
            // 拉新活动数据
            if(this.activity.activityType === 'TMALL_ZHUANKE_INVITE_NEW' && this.activity.zhuankeNewerCpaStatList||
               this.activity.activityType === 'TAOBAO_ZHUANKE_INVITE_NEW' && this.activity.zhuankeNewerCpaStatList){
                 data.zhuankeNewerCpaStatList.forEach((item) => {
                    this.tbListKey.forEach((keyItem, keyIndex) => {
                        if(item.type == 0){
                            this.extensionDataTable[keyIndex].newNum =  item[keyItem] || 0
                        }else if(item.type == 1){
                            this.extensionDataTable[keyIndex].noNewNum =  item[keyItem] || 0
                        }
                    });
                });
            }
        },
        /* eslint-enable */
        async getNewerList() {
            const data = await this.$request({
                url: '/api/report/activity/getNewerList',
                method: 'post',
                data: this.getOrderReportParams()
            });
            data.zhuankeReportNewerDetailList.forEach((value) => {
                Object.assign(value, {statusDesc: ''});
                if(value.pauseStatus === 0) {
                    value.statusDesc = '新客审核中';
                }
                if(value.pauseStatus === 1) {
                    value.statusDesc = '有效新客';
                }
                if(value.pauseStatus === 2) {
                    value.statusDesc = '无效新客';
                }
            });
            this.newerList = data.zhuankeReportNewerDetailList;
            this.totalCount = data.maxCount;
        },
        async getInviteVipRecord() {
            const data = await this.$request({
                url: '/api/report/activity/getInviteVipRecord',
                method: 'post',
                data: this.getOrderReportParams()
            });
            this.vipList = data.zhuankeInviteVipRecordVOList || [];
            this.totalCount = data.maxCount;
        },

        // eslint-disable-next-line complexity
        startSearch(pageIndex = 1, pageSize = 40) {
            this.formData.pageIndex = pageIndex;
            this.formData.pageSize = pageSize;

            // 手猫拉新
            // 不需要订单列表
            if(this.type === 'TMALL_ZHUANKE_INVITE_NEW' || 
               this.type === 'TAOBAO_ZHUANKE_INVITE_NEW') return;

            // 其他活动
            if(this.type === 1 || 
               this.type === 4 || 
               this.type === 5 || 
               (this.type || '').includes('TMALL_') || 
               (this.type || '').includes('TAOBAO_')){
                this.getActivityOrderDetailReport();
            }
            if(this.type === 2) {
                this.getNewerList();
            }
            if(this.type === 3) {
                this.getInviteVipRecord();
            }
        },

        destroyCountDown() {
            if(this.countDown) {
                try {
                    this.countDown._counter && clearInterval(this.countDown._counter);
                } catch (e) {/**/}
            }
        },

        startCountDown() {
            this.destroyCountDown();

            if(this.activity.activityStatus !== 1) {
                return;
            }
            this.countDown = new Countdown({
                totalTime: this.activity.endTime - (+new Date()),
                onUpdate: (t) => {
                    this.remainTime = t;
                },
                onStop: () => {
                    this.activity.activityStatus = 2;
                }
            });
        },

        async getActivityOrderDetailReport() {
            const data = await this.$request({
                url: '/api/report/activity/orders',
                method: 'post',
                data: this.getOrderReportParams()
            });
            this.list = data.zhuankeReportOrderDetailList || [];
            this.totalCount = data.maxCount;
        },

        getOrderReportParams() {
            const m = {
                startTime: +new Date(this.rangeDate[0]),
                endTime: getDayEnd(+new Date(this.rangeDate[1])),
                pageIndex: this.formData.pageIndex || 1,
                pageSize: this.formData.pageSize || 20,
                activityId: this.activityId,
                status: this.formData.settleStatus
            };

            if(this.bizType !== -1) {
                m.bizType = this.bizType;
            }

            return m;
        },

        handleSizeChange(size) {
            this.startSearch(1, size);
        },

        handleCurrentChange(p) {
            this.startSearch(p, this.formData.pageSize);
        },

        handleActivityChange() {
            this.getRewardActivityDetail();
        },

        showExportOrdersPrompt() {
            this.exportVisible = true;
        },

        getExportEmail(email) {
            this.exportOrders(email);
        },

        exportOrders(email) {
            let url = '';
            if(this.type === 1 || this.type === 4 || this.type === 5 || 
               this.type === 'FIRST_ORDER_CPA' || (this.type || '').includes('TMALL_') || (this.type || '').includes('TAOBAO_')) {
                url = '/api/report/activity/export';
            }else if(this.type === 2){
                url = '/api/report/activity/exportNewerReward';
            }else{
                url = '/api/report/activity/exportInviteVipRecord';
            }
           
            this.$request({
                url,
                method: 'post',
                data: {
                    email,
                    ...this.getOrderReportParams()
                },
                origin: true
            }).then((data) => {
                setTimeout(() => {
                    if(data.code === 200) {
                        this.showExportOftenAlert();
                        return;
                    }
                    this.showExportErrorAlert(data.desc || '');
                }, 300);
            }).catch(() => {
                this.showExportErrorAlert();
            });
        },

        showExportErrorAlert(errText) {
            const err = errText ? errText : '导出失败';
            this.$alert(err, '提示', {
                confirmButtonText: '确定'
            });
        },

        showExportOftenAlert() {
            this.$alert('您下载的文件正在生成，请不要重复点击，文件生成后，将会以邮件形式发送给您', '提示', {
                confirmButtonText: '确定'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.reward {
    min-height: 100%;
    position: relative;

    &-inner {
        background: #fff;
        padding: 16px;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        h3 { margin-bottom: 10px; 
            .reward-inner-sub-title{
                font-size: 14px;
            }
        }
        
    }
    
    .reward-info {
        height: 40px;
        &-block {
            margin-right: 20px;
            display: inline-block;
            vertical-align: middle;
        }

        .reward-middle1 {
             height: 40px;
            line-height: 38px;
            vertical-align: middle;
            width: 168px;
        }
        .reward-middle {
            height: 40px;
            line-height: 38px;
            vertical-align: middle;
        }
    }
    .reward-detail {
        margin-top: 20px;
        .reward-amount {
            padding-right: 20px;
            padding-left: 20px;
            display: inline-block;
            border-right: 1px solid #ccc;
            &.un {
                border-right: 0;
            }
            .tip {
                font-size: 14px;
            }
            .money-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .money {
                margin-right: 54px;
                margin-top: 10px;
                font-size: 32px;
                font-weight: bold;
                color: #FF1E32;
                text-align: center;
                .symbol {
                    font-size: 24px;
                }
            }
        }
    }
    .reward-form {
        position: relative;
        .export-btn {
            position: absolute;
            right: 0;
        }
    }
    .reward-page {
        margin-top: 20px;
        text-align: right;
    }

    .reward-expand-content .el-table__empty-block {
        background: #f5f5f5;
    }

    .reward-level {
        margin-top: 20px;

        &.type3 {
            background-color: #f7f7f7;
        }

        &-tip {
            padding: 13px 15px;
            border-radius: 4px;
        }
    }
    .reward-limit-box{
        padding: 0 14px 14px 14px;
        .reward-limit-unit{
            color: #FF1E32;
        }
    }
}

.mt {
    position: absolute;
}
</style>

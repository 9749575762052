/**
 * 创建每局
 * const REWARD_ACTIVITY_SETTLE_STATUS_MAP = {
    PENDING: [1, '待结算'],
    FINISHED: [2, '已结算']
   };
 * let enum = createEnums(REWARD_ACTIVITY_SETTLE_STATUS_MAP);
 * enum.PENDING = 1;
 * enum.get(enum.PENDING) = '待结算';
 * enum.get('PENDING') = '待结算';
 * @param obj
 */
export default function createEnums(obj) {
    let key2numMap = {};
    let key2descMap = {};
    let num2descMap = {};
    for(let key of Object.keys(obj)) {
        let [num, desc] = obj[key];
        key2numMap[key] = num;
        num2descMap[num] = desc;
        key2descMap[key] = desc;
    }
    return {
        ...key2numMap,
        get(code) {
            if(typeof code === 'number') {
                return num2descMap[code] || '';
            }
            return key2descMap[code] || '';
        }
    };
}

/* eslint-disable complexity */
/**
 * 奖励活动公共utils
 */
import createEnums from '@/utils/enum';

/**
 * 奖励活动类型
 * 由于有的地方用的字符串 有的地方用的数字 不适合使用 enums
 *
 * 一个map来对应类型和字符串类型
 * 一个数字来对应数字类型
 */
export const REWARD_ACTIVITY_TYPES_MAP = {
    ORDER_NUM_REWARD: 'ORDER_NUM_REWARD',
    ZHUANKE_INVITE_NEW: 'ZHUANKE_INVITE_NEW',
    ZHUANKE_INVITE_VIP: 'ZHUANKE_INVITE_VIP',
    GMV_REWARD: 'GMV_REWARD',
    GMV_RATE: 'GMV_RATE',
    FIRST_ORDER_CPA: 'FIRST_ORDER_CPA',

    TMALL_ZHUANKE_INVITE_NEW: 'TMALL_ZHUANKE_INVITE_NEW',
    TMALL_ORDER_NUM_REWARD: 'TMALL_ORDER_NUM_REWARD',
    TMALL_GMV_REWARD: 'TMALL_GMV_REWARD',
    TMALL_GMV_RATE: 'TMALL_GMV_RATE',
    TMALL_FLOW_USR_CPA: 'TMALL_FLOW_USR_CPA',
    TAOBAO_ZHUANKE_INVITE_NEW: 'TAOBAO_ZHUANKE_INVITE_NEW',
    TAOBAO_ORDER_NUM_REWARD: 'TAOBAO_ORDER_NUM_REWARD',
    TAOBAO_GMV_REWARD: 'TAOBAO_GMV_REWARD',
    TAOBAO_GMV_RATE: 'TAOBAO_GMV_RATE',
    TAOBAO_RED_ORDER_NUM_REWARD: 'TAOBAO_RED_ORDER_NUM_REWARD'
};
// 考拉海购
const REWARD_NUMBER_ACTIVITY_TYPES = ['',
    REWARD_ACTIVITY_TYPES_MAP.ORDER_NUM_REWARD,
    REWARD_ACTIVITY_TYPES_MAP.ZHUANKE_INVITE_NEW,
    REWARD_ACTIVITY_TYPES_MAP.ZHUANKE_INVITE_VIP,
    REWARD_ACTIVITY_TYPES_MAP.GMV_REWARD,
    REWARD_ACTIVITY_TYPES_MAP.GMV_RATE,
    REWARD_ACTIVITY_TYPES_MAP.FIRST_ORDER_CPA
];

// 猫享自营
const REWARD_NUMBER_ACTIVITY_TYPES_MX = ['',
    REWARD_ACTIVITY_TYPES_MAP.TMALL_ZHUANKE_INVITE_NEW,
    REWARD_ACTIVITY_TYPES_MAP.TMALL_ORDER_NUM_REWARD,
    REWARD_ACTIVITY_TYPES_MAP.TMALL_GMV_REWARD,
    REWARD_ACTIVITY_TYPES_MAP.TMALL_GMV_RATE,
    REWARD_ACTIVITY_TYPES_MAP.TMALL_FLOW_USR_CPA
];

// 天猫商家
const REWARD_NUMBER_ACTIVITY_TYPES_TB = ['',
    REWARD_ACTIVITY_TYPES_MAP.TAOBAO_ZHUANKE_INVITE_NEW,
    REWARD_ACTIVITY_TYPES_MAP.TAOBAO_ORDER_NUM_REWARD,
    REWARD_ACTIVITY_TYPES_MAP.TAOBAO_GMV_REWARD,
    REWARD_ACTIVITY_TYPES_MAP.TAOBAO_GMV_RATE,
    REWARD_ACTIVITY_TYPES_MAP.TAOBAO_RED_ORDER_NUM_REWARD
];


const REWARD_ACTIVITY_TYPES = {
    FIRST_ORDER_CPA: {
        name: '新手出单',
        fullName: '出单奖励',
        orderName: '活动订单'
    },
    ORDER_NUM_REWARD: {
        name: '出单',
        fullName: '出单奖励',
        orderName: '活动订单'
    },
    ZHUANKE_INVITE_NEW: {
        name: '拉新',
        fullName: '拉新奖励',
        orderName: '新客订单'
    },
    ZHUANKE_INVITE_VIP: {
        name: '会员',
        fullName: '邀请会员奖励',
        orderName: '邀请会员记录'
    },
    GMV_REWARD: {
        name: '销售',
        fullName: '销售额奖励',
        orderName: '销售额订单'
    },
    GMV_RATE: {
        name: '加佣',
        fullName: '销售额加佣奖励',
        orderName: '销售额加佣订单'
    },

    TMALL_ZHUANKE_INVITE_NEW: {
        name: '拉新',
        fullName: '拉新奖励',
        orderName: '新客订单'
    },
    TMALL_ORDER_NUM_REWARD: {
        name: '出单',
        fullName: '出单奖励',
        orderName: '活动订单'
    },
    TMALL_GMV_REWARD: {
        name: '销售',
        fullName: '销售额奖励',
        orderName: '销售额订单'
    },
    TMALL_GMV_RATE: {
        name: '加佣',
        fullName: '销售额加佣奖励',
        orderName: '销售额加佣订单'
    },
    TMALL_FLOW_USR_CPA: {
        name: '导流',
        fullName: '导流奖励',
        orderName: '引流人数'
    },
    TAOBAO_ZHUANKE_INVITE_NEW: {
        name: '拉新',
        fullName: '拉新奖励',
        orderName: '新客订单'
    },
    TAOBAO_ORDER_NUM_REWARD: {
        name: '出单',
        fullName: '出单奖励',
        orderName: '活动订单'
    },
    TAOBAO_GMV_REWARD: {
        name: '销售',
        fullName: '销售额奖励',
        orderName: '销售额订单'
    },
    TAOBAO_GMV_RATE: {
        name: '加佣',
        fullName: '销售额加佣奖励',
        orderName: '销售额加佣订单'
    },
    TAOBAO_RED_ORDER_NUM_REWARD: {
        name: '出单',
        fullName: '出单奖励',
        orderName: '红包订单'
    }
};

export const REWARD_ACTIVITY_FILTER_TYPES = REWARD_NUMBER_ACTIVITY_TYPES.map((item, index) => ({
    activityType: index, // 考拉海购 商品用的数字类型
    activityTypeName: item ? REWARD_ACTIVITY_TYPES[item].fullName : '全部'
}));

export const REWARD_ACTIVITY_FILTER_TYPES_MX = REWARD_NUMBER_ACTIVITY_TYPES_MX.map((item) => ({
    activityType: item, // 猫享自营 用的字符串类型
    activityTypeName: item ? REWARD_ACTIVITY_TYPES[item].fullName : '全部'
}));

export const REWARD_ACTIVITY_FILTER_TYPES_TB = REWARD_NUMBER_ACTIVITY_TYPES_TB.map((item) => ({
    activityType: item, // 天猫商家 用的字符串类型
    activityTypeName: item ? REWARD_ACTIVITY_TYPES[item].fullName : '全部'
}));

// 奖励类型
export const REWARD_TYPES = createEnums({
    LEVEL_AMOUNT: [1, '阶梯奖励'],
    FIX_AMOUNT: [2, '定额奖励']
});

// 活动状态
export const REWARD_ACTIVITY_STATUS = createEnums({
    PENDING: [0, '未开始'],
    IN_PROGRESS: [1, '进行中'],
    FINISHED: [2, '已结束']
});

// 活动结算状态
export const REWARD_ACTIVITY_SETTLE_STATUS = createEnums({
    PENDING: [1, '待结算'],
    FINISHED: [2, '已结算']
});

// 订单结算状态
const REWARD_ORDER_SETTLE_STATUS = ['待结算', '已结算', '已失效'];
export const REWARD_ORDER_FILTER_SETTLE_STATUS = ['', ...REWARD_ORDER_SETTLE_STATUS].map((item, index) => ({
    value: index - 1,
    label: item ? item : '全部'
}));

function getStringRewardType(code) {
    let key = code;
    if(typeof code === 'number') {
        key = REWARD_NUMBER_ACTIVITY_TYPES[code];
    }
    return key;
}

// 猫享自营
function isMXFirstOrder(item){
    if(item && item.activityType){
        return item.activityType.includes('TMALL_ORDER_NUM_REWARD') && item.target == 4;
    }
    return false;
}

// 天猫商家 首单
// function isTBFirstOrder(item){
//     if(item && item.activityType){
//         return item.activityType.includes('TAOBAO_ORDER_NUM_REWARD') && item.target == 8;
//     }
//     return false;
// }

/**
 * 格式化类型为标签 ORDER_NUM_REWARD -> 出单
 * 兼容 code 字符串或者数字的情况
 * @param code
 */
export function formatRewardType2Name(item) {
    if(item.activityType === 'TMALL_ORDER_NUM_REWARD' && item.target != 4 || 
       item.activityType === 'TAOBAO_ORDER_NUM_REWARD' ||
       item.activityType === 'TAOBAO_RED_ORDER_NUM_REWARD'){
        return '出单';
    }
    if(isMXFirstOrder(item)){
        return '新手出单';
    }

    return REWARD_ACTIVITY_TYPES[getStringRewardType(item.activityType)].name || '';
}

export function formatRewardType2FullName(code) {

    return REWARD_ACTIVITY_TYPES[getStringRewardType(code)].fullName || '';
}

/**
 * 格式化类型为标签 ORDER_NUM_REWARD -> 活动订单
 * @param code
 * @returns {string|*}
 */
export function formatRewardType2OrderName(code) {
    return REWARD_ACTIVITY_TYPES[getStringRewardType(code)].orderName || '';
}

/**
 * 活动列表筛选某种type的活动列表，type=0为全部
 * @param list
 * @param type = 0
 * @returns {*[]|*}
 */
export function filterRewardList(list, type = '') {
    if(!list || !list.length) {
        return [];
    }
    if(type == '' || type == 0) {
        return [...list];
    }
    return list.filter((item) => item.activityType === type);
}

/**
 * 格式化活动结算状态
 * @param code
 * @returns {string|string}
 */
export function formatRewardActivitySettleStatus(code) {
    return REWARD_ACTIVITY_SETTLE_STATUS.get(code) || '';
}

/**
 * 格式化活动订单结算状态，出单销售等活动订单
 * @param code
 * @returns {string|string}
 */
export function formatRewardOrderSettleStatus(code) {
    return REWARD_ORDER_SETTLE_STATUS[code] || '';
}

/**
 * 格式化我的订单数
 * @param type
 * @param settleStatus
 * @returns {{tip: (string), title: (string)}|{tip: string, title: string}|{}}
 */
export function formatMyOrderNumTitleAndTip(type, settleStatus) {
    switch (getStringRewardType(type)) {
    case REWARD_ACTIVITY_TYPES_MAP.ORDER_NUM_REWARD:
    case REWARD_ACTIVITY_TYPES_MAP.TMALL_ORDER_NUM_REWARD:
    case REWARD_ACTIVITY_TYPES_MAP.TAOBAO_ORDER_NUM_REWARD:
    case REWARD_ACTIVITY_TYPES_MAP.TAOBAO_RED_ORDER_NUM_REWARD:
        return {
            title: '有效订单量',
            tip: '查询时间范围内，按活动汇总的满足活动规则要求的待结算和已结算订单量（该数据已去除失效订单）',
            measure: '订单量'
        };
    case REWARD_ACTIVITY_TYPES_MAP.ZHUANKE_INVITE_NEW:
        return {
            title: settleStatus === REWARD_ACTIVITY_SETTLE_STATUS.FINISHED ? '有效拉新' : '预估拉新',
            tip: settleStatus === REWARD_ACTIVITY_SETTLE_STATUS.FINISHED ? '' : '当前统计到的新客数（含审核中新客），活动总有效新客数可在活动结束后查看。',
            measure: '预估新客'
        };
    case REWARD_ACTIVITY_TYPES_MAP.TMALL_ZHUANKE_INVITE_NEW:
    case REWARD_ACTIVITY_TYPES_MAP.TAOBAO_ZHUANKE_INVITE_NEW:
        return {
            title: '新用户首登且首购、非新用户当日首单',
            tip: settleStatus === REWARD_ACTIVITY_SETTLE_STATUS.FINISHED ? '' : '当前统计到的新用户首登且首购、非新用户当日首单，活动总有效奖励可在活动结束后查看。',
            measure: '新用户首登且首购、非新用户当日首单'
        };
    case REWARD_ACTIVITY_TYPES_MAP.ZHUANKE_INVITE_VIP:
        return {
            title: settleStatus === REWARD_ACTIVITY_SETTLE_STATUS.FINISHED ? '开通黑卡数' : '预估开通黑卡数',
            tip: settleStatus === REWARD_ACTIVITY_SETTLE_STATUS.FINISHED ? '' : '当前统计到的开通黑卡数，活动总有效黑卡数可在活动结束后查看。',
            measure: '预估开通黑卡数'
        };
    case REWARD_ACTIVITY_TYPES_MAP.GMV_REWARD:
    case REWARD_ACTIVITY_TYPES_MAP.GMV_RATE:
    case REWARD_ACTIVITY_TYPES_MAP.FIRST_ORDER_CPA:
    case REWARD_ACTIVITY_TYPES_MAP.TMALL_GMV_REWARD:
    case REWARD_ACTIVITY_TYPES_MAP.TMALL_GMV_RATE:
    case REWARD_ACTIVITY_TYPES_MAP.TAOBAO_GMV_REWARD:
    case REWARD_ACTIVITY_TYPES_MAP.TAOBAO_GMV_RATE:
        return {
            title: '有效销售额',
            tip: '查询时间范围内，按活动汇总的满足活动规则要求的待结算和已结算订单量（该数据已去除失效订单）',
            measure: '有效销售额'
        };
    case REWARD_ACTIVITY_TYPES_MAP.TMALL_FLOW_USR_CPA:
        return {
            title: '有效数量',
            tip: '查询时间范围内，按引流汇总的满足引流规则要求的待结算和已结算数量',
            measure: '有效数量'
        };
    default:
        return {};
    }
}

/**
 * 格式化当前达到的门槛 不同的活动取的字段不一样
 * 销售额激励取 orderAmount
 * 出单 个数类取 myOrderNum
 */
export function formatRewardActivityThrehold(activity) {
    if(activity.activityType && activity.activityType.includes('GMV_RATE') || 
       activity.activityType && activity.activityType.includes('GMV_REWARD')) {
        return activity.orderAmount;
    } 
    return activity.myOrderNum;
    
}

/**
 * 格式化活动未达标文案
 * @param type
 */
export function formatRewardActivityInvolveText(type) {
    switch (getStringRewardType(type)) {
    case REWARD_ACTIVITY_TYPES_MAP.ORDER_NUM_REWARD:
    case REWARD_ACTIVITY_TYPES_MAP.GMV_REWARD:
    case REWARD_ACTIVITY_TYPES_MAP.GMV_RATE:
    case REWARD_ACTIVITY_TYPES_MAP.FIRST_ORDER_CPA:
    case REWARD_ACTIVITY_TYPES_MAP.TMALL_ORDER_NUM_REWARD:
    case REWARD_ACTIVITY_TYPES_MAP.TMALL_GMV_REWARD:
    case REWARD_ACTIVITY_TYPES_MAP.TMALL_GMV_RATE:
    case REWARD_ACTIVITY_TYPES_MAP.TAOBAO_ORDER_NUM_REWARD:
    case REWARD_ACTIVITY_TYPES_MAP.TAOBAO_GMV_REWARD:
    case REWARD_ACTIVITY_TYPES_MAP.TAOBAO_GMV_RATE:
    case REWARD_ACTIVITY_TYPES_MAP.TAOBAO_RED_ORDER_NUM_REWARD:
        return '还未出单';
    case REWARD_ACTIVITY_TYPES_MAP.ZHUANKE_INVITE_NEW:
    case REWARD_ACTIVITY_TYPES_MAP.TMALL_ZHUANKE_INVITE_NEW:
    case REWARD_ACTIVITY_TYPES_MAP.TAOBAO_ZHUANKE_INVITE_NEW:
        return '暂无拉新纪录';
    case REWARD_ACTIVITY_TYPES_MAP.ZHUANKE_INVITE_VIP:
        return '暂无邀请纪录';
    case REWARD_ACTIVITY_TYPES_MAP.TMALL_FLOW_USR_CPA:
        return '暂无引流纪录';
    default:
        return '';
    }
}

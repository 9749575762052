/**
 * params : 
 * value (bizType) :0考拉海购 1猫享自营  2天猫商家（天猫b品）
 * key : value,tagText,title,EN,bottomImg,borderImg,koulingText
 * 
 */
function isEmpty(value) {
    if(value === undefined || value === null || value === '') {
        return true;
    }
    return false;
}

const bottomImgTm = 'https://img.alicdn.com/imgextra/i3/O1CN01IRSoYq1g6BRSoaHlr_!!6000000004092-2-tps-750-70.png';
const borderImgTm = 'https://img.alicdn.com/imgextra/i2/O1CN01V7XRxN1zDwrIeTIhg_!!6000000006681-2-tps-212-258.png';

const bottomImgKl = 'https://kaola-haitao.oss.kaolacdn.com/6eb419e3-c510-4870-8cba-0a2764e53164_750x70.png';
const borderImgKl = 'https://kaola-haitao.oss.kaolacdn.com/e01683c2-7d33-4dff-9333-a285e8fba6ac_212_258.png';

const bizTypeOption = [
    {
        value: 0,
        tagText: '考拉',
        title: '考拉海购',
        EN: 'KAOLA',
        bottomImg: bottomImgKl,
        borderImg: borderImgKl,
        koulingText: '复制这段描述后，打开考拉海购APP',
        koulingTitle: '【考拉海购】'
    }, {
        value: 1,
        tagText: '猫享',
        title: '猫享自营',
        EN: 'TMALL',
        bottomImg: bottomImgTm,
        borderImg: borderImgTm,
        koulingText: '复制这段描述后，打开最新版手猫APP享优惠',
        koulingTitle: '【猫享自营】'
    }, {
        value: 2,
        tagText: '天猫',
        title: '天猫商家',
        EN: 'TMALL',
        bottomImg: bottomImgTm,
        borderImg: borderImgTm,
        koulingText: '复制这段描述后，打开最新版手猫APP享优惠',
        koulingTitle: '【手机天喵】'
    }
];

const bizTypeFindByKey = (value, key) => {
    if (isEmpty(value) && key) {
        return bizTypeOption[0][key];
    }
    return bizTypeOption[value][key];
};

const bizTypeFindForItem = (value) => {
    if (isEmpty(value)) {
        return bizTypeOption[0];
    }
    return bizTypeOption[value];
};

export { bizTypeOption, bizTypeFindByKey, bizTypeFindForItem };